import * as React from "react"
import Footer from "../components/Footer"
import Header from "../components/Header";

const FastTravelPage = () => {
    return (
        <main>
            <Header/>
            <Footer/>
        </main>
    )
}

export default FastTravelPage